<template>
  <div class="filter-cast text-left f-w3 row" id="filter-cast">
    <template v-if="isLoading">
      <AppLoading />
    </template>
    <template v-else>
      <div class="col-lg-6 filter-cast-left">
        <div
          class="d-flex justify-content-between align-items-center play-area"
        >
          <label class="play-area-label f-w6" for="">プレーエリア</label>
          <p class="play-area__p">※複数選択可</p>
        </div>
        <SelectMultiFilterCast
          :options="commonTutorials.golfAreas"
          v-model="areaValue"
          @select-option="areaValue = $event"
        />
        <div class="plan d-flex align-items-center pl-0">
          <label class="plan-label f-w6" for="">プラン</label>
          <div
            class="plan-icon d-flex align-items-center justify-content-center"
          >
            ?
          </div>
        </div>
        <div class="plan__checkbox d-flex">
          <div class="meeting-area d-flex align-items-center">
            <div
              class="
                meeting-area__checkbox
                d-flex
                justify-content-center
                align-items-center
              "
              :class="isChooseMeeting ? 'meeting-area__checkbox-active' : ''"
              @click="chooseMeeting()"
            >
              <div
                class="checkbox"
                :class="isChooseMeeting ? 'checkbox-active' : ''"
              />
            </div>
            現地集合
          </div>
          <div class="pickup d-flex align-items-center">
            <div
              class="
                pickup__checkbox
                d-flex
                justify-content-center
                align-items-center
              "
              :class="isChoosePickUp ? 'pickup__checkbox-active' : ''"
              @click="choosePickup()"
            >
              <div
                class="checkbox"
                :class="isChoosePickUp ? 'checkbox-active' : ''"
              />
            </div>
            ピックアップ
          </div>
        </div>
        <div v-if="isChoosePickUp" class="area">
          <div class="divide d-flex align-items-center justify-content-between">
            <div class="divide--left" />
            エリアを選択
            <div class="divide--right" />
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div
              class="score position-relative score-province"
              :class="isShowProvince ? 'active' : ''"
              :tabindex="tabindex"
              @blur="isShowProvince = false"
            >
              <div class="area-detail d-flex align-items-center">
                <label class="area-detail-label" for="">距離</label>
              </div>
              <div
                class="drop-down d-flex align-items-center"
                @click="isShowProvince = !isShowProvince"
              >
                <span class="score__text">{{
                  this.distance != null ? distanceList[this.distance] : null
                }}</span>
              </div>
              <div class="position-absolute score-option score-option-province">
                <div
                  class="score-option__items"
                  v-for="(distance, index) in distanceList"
                  :class="province == index ? 'active' : ''"
                  :key="index"
                  @click="chooseDistance(index)"
                >
                  {{ distance }}
                </div>
              </div>
            </div>
            <!-- <div
              class="score position-relative score-area-detail"
              :class="isShowAreaName ? 'active' : ''"
              :tabindex="tabindex"
              @blur="isShowAreaName = false"
            >
              <div class="area-detail d-flex align-items-center">
                <label class="area-detail-label" for="">詳細エリア</label>
                <div
                  class="
                    area-detail-icon
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                  @click="showImage()"
                >
                  ?
                </div>
              </div>
              <div
                class="drop-down d-flex align-items-center"
                :class="area.length > 0 ? '' : 'drop-down-area-detail'"
                @click="isShowAreaName = !isShowAreaName"
              >
                <span class="score__text area">{{
                  areaName != null ? area[areaName].name : null
                }}</span>
              </div>
              <div class="position-absolute score-option score-option-province">
                <div
                  class="score-option__items areas"
                  v-for="(provinceOption, index) in area"
                  :class="areaName == index ? 'active' : ''"
                  :key="index"
                  @click="chooseArea(index)"
                >
                  {{ provinceOption.name }}
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="divide d-flex align-items-center justify-content-between">
          <div class="divide--left" />
          キャスト スコア
          <div class="divide--right" />
        </div>
        <div class="best-score">
          <label class="best-score-label f-w6" for="">ベストスコア</label>
          <div class="d-flex justify-content-between align-items-center">
            <div
              class="score position-relative"
              :class="isShowBestScoreLower ? 'active' : ''"
              :tabindex="tabindex"
              @blur="isShowBestScoreLower = false"
            >
              <div
                class="drop-down d-flex align-items-center"
                @click="isShowBestScoreLower = !isShowBestScoreLower"
              >
                <span class="score__text">{{ bestScoreLower }}</span>
              </div>
              <div class="position-absolute score-option">
                <div
                  class="score-option__items"
                  v-for="(bestScore, index) in listBestScoreLower"
                  :class="
                    bestScoreLower == listBestScoreLower[index] ? 'active' : ''
                  "
                  :key="index"
                  @click="chooseBestScoreLower(index)"
                >
                  {{ listBestScoreLower[index] }}
                </div>
              </div>
            </div>
            <span class="f-w6 score__span">~</span>
            <div
              class="score position-relative"
              :class="isShowBestScoreUpper ? 'active' : ''"
              :tabindex="tabindex"
              @blur="isShowBestScoreUpper = false"
            >
              <div
                class="drop-down d-flex align-items-center"
                @click="isShowBestScoreUpper = !isShowBestScoreUpper"
              >
                <span class="score__text">{{ bestScoreUpper }}</span>
              </div>
              <div class="position-absolute score-option">
                <div
                  class="score-option__items"
                  v-for="(bestScore, index) in listBestScoreUpper"
                  :class="
                    bestScoreUpper == listBestScoreUpper[index] ? 'active' : ''
                  "
                  :key="index"
                  @click="chooseBestScoreUpper(index)"
                >
                  {{ listBestScoreUpper[index] }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="avg-score">
          <label class="best-score-label f-w6" for="">アベレージスコア</label>
          <div class="d-flex justify-content-between align-items-center">
            <div
              class="score position-relative"
              :class="isShowAvgScoreLower ? 'active' : ''"
              :tabindex="tabindex"
              @blur="isShowAvgScoreLower = false"
            >
              <div
                class="drop-down d-flex align-items-center"
                @click="isShowAvgScoreLower = !isShowAvgScoreLower"
              >
                <span class="score__text">{{ avgScoreLower }}</span>
              </div>
              <div class="position-absolute score-option">
                <div
                  class="score-option__items"
                  v-for="(avgScore, index) in listAvgScoreLower"
                  :class="
                    avgScoreLower == listAvgScoreLower[index] ? 'active' : ''
                  "
                  :key="index"
                  @click="chooseAvgScoreLower(index)"
                >
                  {{ listAvgScoreLower[index] }}
                </div>
              </div>
            </div>
            <span class="f-w6 score__span">~</span>
            <div
              class="score position-relative"
              :class="isShowAvgScoreUpper ? 'active' : ''"
              :tabindex="tabindex"
              @blur="isShowAvgScoreUpper = false"
            >
              <div
                class="drop-down d-flex align-items-center"
                @click="isShowAvgScoreUpper = !isShowAvgScoreUpper"
              >
                <span class="score__text">{{ avgScoreUpper }}</span>
              </div>
              <div class="position-absolute score-option">
                <div
                  class="score-option__items"
                  v-for="(avgScore, index) in listAvgScoreUpper"
                  :class="
                    avgScoreUpper == listAvgScoreUpper[index] ? 'active' : ''
                  "
                  :key="index"
                  @click="chooseAvgScoreUpper(index)"
                >
                  {{ listAvgScoreUpper[index] }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="divide-max" />
        <div class="golf-rank d-flex align-items-center">
          <label class="golf-rank-label f-w6" for="">ゴルフレベル</label>
          <div
            class="
              golf-rank-icon
              d-flex
              align-items-center
              justify-content-center
            "
          >
            ?
          </div>
        </div>
        <div class="golf-rank-btn">
          <button
            type="button"
            v-for="(item, index) in commonTutorials.femaleGolfRanks"
            :key="index"
            @click="golfRabkActived(item.female_golf_rank_id)"
            :class="
              female_golf_rank_ids.indexOf(item.female_golf_rank_id) > -1
                ? 'actived'
                : ''
            "
            class="btn-commons"
          >
            {{ item.name }}
          </button>
        </div>
        <div class="age">
          <p class="age-label f-w6" for="">年齢</p>
          <div class="d-flex justify-content-between align-items-center">
            <div
              class="score position-relative"
              :class="isShowAgeLower ? 'active' : ''"
              :tabindex="tabindex"
              @blur="isShowAgeLower = false"
            >
              <div
                class="drop-down d-flex align-items-center"
                @click="isShowAgeLower = !isShowAgeLower"
              >
                <span class="score__text">{{ ageLower }}</span>
              </div>
              <div class="position-absolute score-option">
                <div
                  class="score-option__items"
                  v-for="(age, index) in listAgeLower"
                  :class="ageLower == listAgeLower[index] ? 'active' : ''"
                  :key="index"
                  @click="chooseAgeLower(index)"
                >
                  {{ listAgeLower[index] }}
                </div>
              </div>
            </div>
            <span class="f-w6 score__span">~</span>
            <div
              class="score position-relative"
              :class="isShowAgeUpper ? 'active' : ''"
              :tabindex="tabindex"
              @blur="isShowAgeUpper = false"
            >
              <div
                class="drop-down d-flex align-items-center"
                @click="isShowAgeUpper = !isShowAgeUpper"
              >
                <span class="score__text">{{ ageUpper }}</span>
              </div>
              <div class="position-absolute score-option">
                <div
                  class="score-option__items"
                  v-for="(age, index) in listAgeUpper"
                  :class="ageUpper == listAgeUpper[index] ? 'active' : ''"
                  :key="index"
                  @click="chooseAgeUpper(index)"
                >
                  {{ listAgeUpper[index] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 filter-cast-right">
        <div class="golf-rank golf-rank-right d-flex align-items-center">
          <label class="golf-rank-label f-w6" for="">キャストタイプ</label>
          <div
            class="
              golf-rank-icon
              d-flex
              align-items-center
              justify-content-center
            "
          >
            ?
          </div>
        </div>
        <div class="cast-type-btn">
          <button
            type="button"
            v-for="(item, index) in commonTutorials.appearances"
            :key="index"
            @click="castTypeActived(item.appearance_id)"
            :class="
              appearance_ids.indexOf(item.appearance_id) > -1 ? 'actived' : ''
            "
            class="btn-commons"
          >
            {{ item.name }}
          </button>
        </div>
        <div
          class="
            horizontal-line
            d-flex
            align-items-center
            justify-content-between
          "
        >
          <div class="horizontal-line--left" />
          男性のSuperオファーに対して
          <div class="horizontal-line--right" />
        </div>
        <div class="d-flex justify-content-center">
          <button
            type="button"
            class="btn mr-3"
            :class="singleMeeting == 0 ? 'actived' : ''"
            @click="singleMeetingActived(0)"
          >
            1人参加NG
          </button>
          <button
            type="button"
            class="btn"
            :class="singleMeeting == 1 ? 'actived' : ''"
            @click="singleMeetingActived(1)"
          >
            1人参OK
          </button>
        </div>
        <div class="horizontal-line-max" />
        <div
          class="d-flex profession justify-content-between align-items-center"
        >
          <label class="profession-label f-w6" for="">職業</label>
          <p class="profession__p">※複数選択可</p>
        </div>
        <div class="profession-btn">
          <button
            type="button"
            v-for="(item, index) in commonTutorials.occupations"
            :key="index"
            @click="professionActived(item.occupation_id)"
            :class="
              occupation_ids.indexOf(item.occupation_id) > -1 ? 'actived' : ''
            "
            class="btn-commons"
          >
            {{ item.name }}
          </button>
        </div>
        <!-- <label class="price-thirty f-w6">30分あたりのポイント</label>
        <div class="slider">
          <VueRangeSlider
            v-model="value"
            :min="min"
            :max="max"
            :step="250"
            :bg-style="bgStyle"
            :formatter="formatter"
            :tooltip-style="tooltipStyle"
            :process-style="processStyle"
          />
        </div>
        <div class="d-flex justify-content-between point">
          <span class="point__span">750P</span>
          <span class="point__span">8000P</span>
        </div> -->
      </div>
      <div class="footer-btn">
        <button class="btn-submit" @click="searchUser()" type="submit">
          検索する
        </button>
        <div class="blur-filter" />
      </div>
    </template>
    <template v-if="province != null && isShowImage">
      <div class="image-show">
        <button class="btn-sm lb-close" @click="closeShowImage">
          <img src="@/assets/image/background/icon-close.svg" alt="" />
        </button>
        <div class="image">
          <img
            :src="commonTutorials.prefectures[province].image_url"
            class="image-show-preview"
            alt=""
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import "vue-range-component/dist/vue-range-slider.css";
// import VueRangeSlider from "vue-range-component";
import SelectMultiFilterCast from "@/views/Cast/MultiSelectFilter";
// import ModalImage from "@/components/ModalImage.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    // VueRangeSlider,
    SelectMultiFilterCast
    // ModalImage,
  },
  name: "FilterCastPopup",

  computed: {
    ...mapGetters({
      commonTutorials: "common/commonTutorials",
      isLoading: "common/isLoading"
    })
  },

  props: {
    tabindex: {
      type: Number,
      require: null,
      default: 0
    }
  },
  async created() {
    this.$store.dispatch("common/setIsLoading", true);
    this.min = 750;
    this.max = 8000;
    this.bgStyle = {
      backgroundColor: "#fff",
      boxShadow: "inset 0.5px 0.5px 3px 1px rgba(0,0,0,.36)"
    };
    (this.tooltipStyle = {
      backgroundColor: "#464d77",
      borderColor: "#464d77"
    }),
      (this.processStyle = {
        backgroundColor: "#464d77"
      });
    this.formatter = value => `${value}P`;
    for (let i = 60; i <= 160; i++) {
      this.listBestScoreLower.push(i);
      this.listBestScoreUpper.push(i);
    }
    this.listBestScoreLower.push("160以上");
    this.listBestScoreUpper.push("160以上");
    for (let i = 60; i <= 180; i += 5) {
      this.listAvgScoreLower.push(i);
      this.listAvgScoreUpper.push(i);
    }
    this.listAvgScoreLower.push("180以上");
    this.listAvgScoreUpper.push("180以上");
    for (let i = 20; i <= 100; i++) {
      this.listAgeLower.push(i);
      this.listAgeUpper.push(i);
    }
    this.distanceList.push("５km以内");
    for (let i = 6; i <= 10; i += 1) {
      const distance = i + "km";
      this.distanceList.push(distance);
    }
    for (let i = 3; i <= 20; i++) {
      const distance = i * 5 + "km";
      this.distanceList.push(distance);
    }
    this.distanceList.push("100km以上");
    await this.$store.dispatch("common/commonsTutorials", {
      array_common_request: [
        "prefectures",
        "residences",
        "occupations",
        "golfExperiences",
        "golfAreas",
        "golfFrequencies",
        "cigarettes",
        "alcohols",
        "annualIncomes",
        "relationships",
        "appearances",
        "figures",
        "femaleGolfRanks",
        "otherPreferences",
        "areas"
      ]
    });
    this.$store.dispatch("common/setIsLoading", false);
  },
  data() {
    return {
      isShowAvgScoreUpper: false,
      isShowAvgScoreLower: false,
      isShowBestScoreUpper: false,
      isShowBestScoreLower: false,
      isShowAreaName: false,
      area: [],
      areaName: null,
      isShowAgeUpper: false,
      isShowAgeLower: false,
      isShowProvince: false,
      isShowImage: false,
      ageLower: 20,
      ageUpper: 100,
      province: null,
      bestScoreUpper: "160以上",
      bestScoreLower: 60,
      avgScoreLower: 60,
      avgScoreUpper: "180以上",
      indexBestScoreLower: 0,
      indexBestScoreUpper: 101,
      indexAvgScoreLower: 0,
      indexAvgScoreUpper: 25,
      listAgeLower: [],
      listAgeUpper: [],
      listAvgScoreLower: [],
      listAvgScoreUpper: [],
      listBestScoreLower: [],
      listBestScoreUpper: [],
      show: false,
      value: [750, 8000],
      isShowArea: false,
      areaValue: [],
      isChooseMeeting: false,
      isChoosePickUp: false,
      showResidence: 0,
      singleMeeting: null,
      listPlan: [
        { name: "現地集合", value: "1", status: false },
        { name: "ピックアップ", value: "2", status: false }
      ],
      listGolfRank: [
        { name: "ルーキー", value: "1", status: false },
        { name: "ビギナー", value: "2", status: false },
        { name: "ミドル", value: "3", status: false },
        { name: "エキスパート", value: "4", status: false },
        { name: "マスター", value: "5", status: false },
        { name: "シングル", value: "6", status: false },
        { name: "シングル", value: "7", status: false }
      ],
      female_golf_rank_ids: [],
      appearance_ids: [],
      occupation_ids: [],
      listProfession: [
        { name: "公務員", value: "1", status: false },
        { name: "事務職", value: "2", status: false },
        { name: "アパレル", value: "3", status: false },
        { name: "医師看護師", value: "4", status: false },
        { name: "クリエーター", value: "5", status: false },
        { name: "メディア", value: "6", status: false },
        { name: "秘書、受付", value: "7", status: false },
        { name: "スポーツ関連", value: "8", status: false },
        { name: "モデル芸能", value: "9", status: false },
        { name: "ホステス", value: "10", status: false },
        { name: "サービス業", value: "11", status: false },
        { name: "美容、エステ", value: "12", status: false },
        { name: "経営、自営", value: "13", status: false },
        { name: "会社役員", value: "14", status: false },
        { name: "航空関連", value: "15", status: false },
        { name: "不動産関連", value: "16", status: false },
        { name: "営業", value: "17", status: false },
        { name: "IT通信関連", value: "18", status: false },
        { name: "弁護士", value: "19", status: false },
        { name: "会計士", value: "20", status: false },
        { name: "社労士", value: "21", status: false },
        { name: "主婦", value: "22", status: false },
        { name: "家事手伝い", value: "23", status: false },
        { name: "学生", value: "24", status: false },
        { name: "その他", value: "25", status: false }
      ],
      listCastType: [
        { name: "綺麗系", value: "1", status: false },
        { name: "清楚系", value: "2", status: false },
        { name: "可愛い系", value: "3", status: false },
        { name: "ギャル系", value: "4", status: false },
        { name: "スレンダー系", value: "5", status: false },
        { name: "その他", value: "6", status: false }
      ],
      listArea: [
        { name: "綺麗系", value: "1", status: false },
        { name: "清楚系", value: "2", status: false },
        { name: "可愛い系", value: "3", status: false },
        { name: "ギャル系", value: "4", status: false },
        { name: "スレンダー系", value: "5", status: false },
        { name: "その他", value: "6", status: false }
      ],
      selectedArea: [],
      distanceList: [],
      distance: null
    };
  },
  methods: {
    closeShowImage() {
      this.isShowImage = false;
    },
    showImage() {
      if (this.province != null) {
        this.isShowImage = true;
      }
    },
    chooseMeeting() {
      this.isChooseMeeting = !this.isChooseMeeting;
      this.isChoosePickUp = false;
    },
    choosePickup() {
      this.isChooseMeeting = false;
      this.isChoosePickUp = !this.isChoosePickUp;
    },
    async chooseProvince(index) {
      if (this.province != index) {
        this.areaName = null;
      }
      this.province = index;
      this.isShowProvince = false;
      await this.$store
        .dispatch(
          "userApp/getAreas",
          this.commonTutorials.prefectures[this.province].prefecture_id
        )
        .then(response => {
          this.area = response.data;
        });
    },
    chooseAvgScoreLower(index) {
      this.avgScoreLower = this.listAvgScoreLower[index];
      this.indexAvgScoreLower = index;
      if (this.indexBestScoreUpper < this.indexAvgScoreLower) {
        this.avgScoreUpper = null;
      }
      this.isShowAvgScoreLower = false;
    },
    chooseAvgScoreUpper(index) {
      this.avgScoreUpper = this.listAvgScoreUpper[index];
      this.indexBestScoreUpper = index;
      if (this.indexBestScoreUpper < this.indexAvgScoreLower) {
        this.avgScoreLower = null;
      }
      this.isShowAvgScoreUpper = false;
    },
    chooseBestScoreLower(index) {
      this.bestScoreLower = this.listBestScoreLower[index];
      this.indexBestScoreLower = index;
      if (this.indexBestScoreUpper < this.indexBestScoreLower) {
        this.bestScoreUpper = null;
      }
      this.isShowBestScoreLower = false;
    },
    chooseBestScoreUpper(index) {
      this.bestScoreUpper = this.listBestScoreUpper[index];
      this.indexBestScoreUpper = index;
      if (this.indexBestScoreUpper < this.indexBestScoreLower) {
        this.bestScoreLower = null;
      }
      this.isShowBestScoreUpper = false;
    },
    golfRabkActived(index) {
      if (this.female_golf_rank_ids.indexOf(index) === -1) {
        this.female_golf_rank_ids.push(index);
      } else {
        const i = this.female_golf_rank_ids.indexOf(index);
        this.female_golf_rank_ids.splice(i, 1);
      }
    },
    castTypeActived(index) {
      if (this.appearance_ids.indexOf(index) === -1) {
        this.appearance_ids.push(index);
      } else {
        const i = this.appearance_ids.indexOf(index);
        this.appearance_ids.splice(i, 1);
      }
    },
    singleMeetingActived(index) {
      if (this.singleMeeting == index) {
        this.singleMeeting = null;
        return;
      }
      this.singleMeeting = index;
    },
    professionActived(index) {
      if (this.occupation_ids.indexOf(index) === -1) {
        this.occupation_ids.push(index);
      } else {
        const i = this.occupation_ids.indexOf(index);
        this.occupation_ids.splice(i, 1);
      }
    },
    chooseAgeLower(index) {
      this.ageLower = this.listAgeLower[index];
      if (this.ageUpper < this.ageLower) {
        this.ageUpper = null;
      }
      this.listAgeUpper = [];
      for (let i = this.ageLower; i <= 100; i++) {
        this.listAgeUpper.push(i);
      }
      this.isShowAgeLower = false;
    },
    chooseAgeUpper(index) {
      this.ageUpper = this.listAgeUpper[index];
      if (this.ageUpper < this.ageLower) {
        this.ageLower = null;
      }
      this.listAgeLower = [];
      for (let i = 20; i <= this.ageUpper; i++) {
        this.listAgeLower.push(i);
      }
      this.isShowAgeUpper = false;
    },
    chooseArea(index) {
      this.areaName = index;
      this.isShowAreaName = false;
    },
    chooseDistance(index) {
      this.distance = index;
      this.isShowProvince = false;
    },
    searchUser() {
      let meeting_method = null;
      if (this.isChoosePickUp) {
        meeting_method = 1;
      } else if (this.isChooseMeeting) {
        meeting_method = 2;
      }
      let golf_area_ids = [];
      for (let i = 0; i < this.areaValue.length; i++) {
        golf_area_ids.push(
          this.commonTutorials.golfAreas[this.areaValue[i]].golf_area_id
        );
      }
      let perpage = 25;
      if (window.innerWidth < 992) {
        perpage = 8;
      }
      const params = {
        type: this.$route.query.type ? this.$route.query.type : 1,
        page: 1,
        age_lower: this.ageLower,
        age_upper: this.ageUpper,
        perpage: perpage,
        golf_area_ids:
          golf_area_ids.length >= 1 ? golf_area_ids.toString() : null,
        best_score_upper:
          this.bestScoreUpper != "160以上" ? this.bestScoreUpper : 999,
        best_score_lower:
          this.bestScoreLower != "160以上" ? this.bestScoreLower : 999,
        avg_score_lower:
          this.avgScoreLower != "180以上" ? this.avgScoreLower : 999,
        avg_score_upper:
          this.avgScoreUpper != "180以上" ? this.avgScoreUpper : 999,
        meeting_method: meeting_method,
        appearance_ids:
          this.appearance_ids.length >= 1
            ? this.appearance_ids.toString()
            : null,
        occupation_ids:
          this.occupation_ids.length >= 1
            ? this.occupation_ids.toString()
            : null,
        female_golf_rank_ids:
          this.female_golf_rank_ids.length >= 1
            ? this.female_golf_rank_ids.toString()
            : null,
        prefecture_ids:
          this.province != null && meeting_method == 1
            ? this.commonTutorials.prefectures[
                this.province
              ].prefecture_id.toString()
            : null,
        single_participant: this.singleMeeting,
        ["30_minutes_pay_lower"]: this.value[0],
        ["30_minutes_pay_upper"]: this.value[1],
        area_id:
          this.areaName != null && meeting_method == 1
            ? this.area[this.areaName].area_id
            : null
      };
      if (this.distance === 0) {
        params["distance_lower"] = 5;
      } else if (this.distance > 23) {
        params["distance_upper"] = 100;
      } else if (this.distance <= 5) {
        params["distance_lower"] = this.distance + 5;
        params["distance_upper"] = this.distance + 4;
      } else if (this.distance != null) {
        params["distance_lower"] = (this.distance - 3) * 5;
        params["distance_upper"] = (this.distance - 4) * 5;
      }
      this.$emit("searchCast", params);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/filterCastPopup.scss";
@import "@/assets/scss/_fontFamily.scss";
.image-show {
  transition: opacity 0.2s ease;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  min-height: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: block;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .lb-close {
    border: none;
    box-shadow: none;
    outline: none;
    position: absolute;
    right: 20px;
    top: 20px;
    color: #fff;
    padding: 0.56rem 0.5rem !important;
    background-color: transparent;
  }
  .lb-pre {
    position: absolute;
    outline: none;
    border: none;
    box-shadow: none;
    margin-top: 0;
    right: auto;
    left: 20px;
    top: 48%;
  }
  .lb-next {
    position: absolute;
    outline: none;
    border: none;
    box-shadow: none;
    margin-top: 0;
    left: auto;
    right: 20px;
    top: 48%;
  }
  .image {
    position: absolute;
    top: 10px;
    left: 70px;
    right: 70px;
    bottom: 10px;
    text-align: center;
    &::before {
      display: inline-block;
      content: "";
      vertical-align: middle;
      height: 100%;
    }
    &-show-preview {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
      -o-object-fit: contain;
      object-fit: contain;
      pointer-events: none;
    }
  }
}
</style>

<style lang="scss">
.vue-range-slider.slider-component .slider .slider-dot .slider-dot-handle {
  background-color: #464d77;
  box-shadow: none;
}
</style>
